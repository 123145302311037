import React, { useEffect, useState } from "react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const COMETCHAT_CONSTANTS = {
  APP_ID: "2507641c4fafd871",
  REGION: "EU",
  AUTH_KEY: "3d35c30bc412accc62d26140125ab94488be325c",
};

const ConferenceCall = () => {
  const { sessionID: paramSessionID } = useParams();
  const [sessionID, setSessionID] = useState("");
  const [callSettings, setCallSettings] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [isPresenter, setIsPresenter] = useState(false);

  useEffect(() => {
    initializeCometChat();
  }, []);

  const initializeCometChat = () => {
    CometChat.init(
      COMETCHAT_CONSTANTS.APP_ID,
      new CometChat.AppSettingsBuilder()
        .setRegion(COMETCHAT_CONSTANTS.REGION)
        .build()
    )
      .then(() => {
        console.log("CometChat initialized successfully");
        loginUser();
      })
      .catch((error) => {
        console.log("CometChat initialization failed with error:", error);
      });
  };

  const loginUser = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
    const UID = userInfo.id;

    if (!UID) {
      console.log("User ID not found");
      return;
    }

    CometChat.login(UID, COMETCHAT_CONSTANTS.AUTH_KEY)
      .then((user) => {
        console.log("Login Successful:", user);
        if (paramSessionID) {
          setIsPresenter(false); // Joining as a viewer
          joinConferenceCall(paramSessionID);
        } else {
          setIsPresenter(true); // Creating as a presenter
          createConferenceCall();
        }
      })
      .catch((error) => {
        console.log("Login failed with error:", error);
      });
  };

  const createConferenceCall = () => {
    const generatedSessionID = uuidv4();
    setSessionID(generatedSessionID);

    const settings = new CometChat.CallSettingsBuilder()
      .setSessionID(generatedSessionID)
      .enableDefaultLayout(true)
      .setIsAudioOnlyCall(false)
      .build();

    setCallSettings(settings);
    setCallInProgress(true);
  };

  const joinConferenceCall = (existingSessionID) => {
    setSessionID(existingSessionID);

    const settings = new CometChat.CallSettingsBuilder()
      .setSessionID(existingSessionID)
      .enableDefaultLayout(true)
      .setIsAudioOnlyCall(false)
      .build();

    setCallSettings(settings);
    setCallInProgress(true);
  };

  const renderCall = () => {
    if (callSettings) {
      return (
        <div
          id="conference-call"
          style={{ width: "70%", height: "500px", float: "left" }}
        />
      );
    }
    return null;
  };

  const renderChat = () => {
    return (
      <div
        style={{
          width: "30%",
          float: "right",
          borderLeft: "1px solid #ccc",
          height: "500px",
        }}
      >
        {/* Embed your chat component here */}
        <h3>Live Chat</h3>
        {/* Example: <CometChatMessages chatWithGroup={sessionID} /> */}
        {/* Add the CometChatMessages component to display the chat */}
      </div>
    );
  };

  useEffect(() => {
    if (callInProgress && callSettings) {
      CometChat.startCall(
        callSettings,
        document.getElementById("conference-call"),
        new CometChat.OngoingCallListener({
          onUserJoined: (user) => {
            console.log("User joined the call:", user);
          },
          onUserLeft: (user) => {
            console.log("User left the call:", user);
          },
          onCallEnded: (call) => {
            console.log("Call ended:", call);
            setCallInProgress(false);
          },
          onError: (error) => {
            console.log("Error during the call:", error);
          },
        })
      );
    }
  }, [callInProgress, callSettings]);

  return (
    <div>
      <h2>
        {paramSessionID ? "Join Conference Call" : "Create Conference Call"}
      </h2>
      <p>Conference ID: {sessionID}</p>
      {!paramSessionID && sessionID && (
        <p>
          Share this link to join: {window.location.origin}/ConferenceCall/
          {sessionID}
        </p>
      )}
      <div style={{ display: "flex" }}>
        {renderCall()}
        {renderChat()}
      </div>
    </div>
  );
};

export default ConferenceCall;
